import "./App.css";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import { BrowserRouter, Link } from "react-router-dom";

import Contact from "./contact";
import About from "./about";
import Navbr from "./navbar";
import Home from "./home";
import Work from "./work";

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="justify-center bg-gradient-to-tr from-white to-slate h-auto w-auto items-center ">
      <div className="justify-center bg-gradient-to-tr from-white to-slate  w-screen ">
        <Navbar
          bg="muted"
          data-bs-theme="light"
          fluid
          className="fluid text-center text-gray-900"
        >
          <Container align="center">
            <Nav className="mx-auto items-center text-center ">
              <Nav.Link href="/home">Home</Nav.Link>
              <Nav.Link href="./about">About</Nav.Link>

              <Nav.Link href="./contact">Contact</Nav.Link>

              <Nav.Link href="./work">Work</Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </div>

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/work" element={<Work />} />
      </Routes>
    </div>
  );
}

export default App;
