import "./App.css";

import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

export function CardDefault() {
  return (
    <Card className="mt-7 w-screen">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://static.thenounproject.com/png/3974682-200.png"
          alt="card-image"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          File Downloader Application
        </Typography>
        <Typography>
          A simple file downloader web application: Vanilla Javascript, HTML,
          CSS
        </Typography>
      </CardBody>
      <CardFooter className="pt-0">
        <Button>Visit Site</Button>
      </CardFooter>
    </Card>
  );
}

export function CardDefault1() {
  return (
    <Card className="mt-7 w-screen">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://hd2.tudocdn.net/1125517?w=824&h=494"
          alt="card-image"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Job Search Android Application
        </Typography>
        <Typography>
          A job board Android Application: React Native, Expo, JSX, JavaScript
        </Typography>
      </CardBody>
      <CardFooter className="pt-0">
        <Button>Visit Site</Button>
      </CardFooter>
    </Card>
  );
}

export function CardDefault2() {
  return (
    <Card className="mt-7 w-screen">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://images.unsplash.com/photo-1636227008751-1b2708e36e7b?q=80&w=2837&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="card-image"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Electrify
        </Typography>
        <Typography>EV awareness website</Typography>
      </CardBody>
      <CardFooter className="pt-0">
        <Button>Visit Site</Button>
      </CardFooter>
    </Card>
  );
}

export function CardDefault3() {
  return (
    <Card className="mt-7 w-screen">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://images.unsplash.com/photo-1593941707882-a5bba14938c7?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="card-image"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          EV Store
        </Typography>
        <Typography>Simple EV store: React, Javascript, JSX</Typography>
      </CardBody>
      <CardFooter className="pt-0">
        <Button>Visit Site</Button>
      </CardFooter>
    </Card>
  );
}

export function HorizontalCard() {
  return (
    <Card className="w-full m-2 flex-row">
      <CardHeader
        shadow={false}
        floated={false}
        className="m-0 w-2/5 shrink-0 rounded-r-none"
      >
        <img
          src="https://t3.ftcdn.net/jpg/07/50/66/76/360_F_750667617_Na9p9ytZR0R404qemYqhOjrMbc2sDix5.jpg"
          alt="card-image"
          className="h-full w-full object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          File Downloader Application
        </Typography>
        <Typography color="gray" className="mb-8 font-normal">
          A simple file downloader web application: Vanilla Javascript, HTML,
          CSS
        </Typography>
        <a
          href="https://stephen0111.github.io/ZingaFileDownloader/"
          target="_blank"
          className="inline-block"
        >
          <Button variant="text" className="flex items-center gap-2">
            Visit Site
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </a>
      </CardBody>
    </Card>
  );
}

export function HorizontalCard1() {
  return (
    <Card className="w-full m-2  flex-row">
      <CardHeader
        shadow={false}
        floated={false}
        className="m-0 w-2/5 shrink-0 rounded-r-none"
      >
        <img
          src="https://ts2.mm.bing.net/th?q=chat%20icons%20free"
          alt="card-image"
          className="h-full w-full object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          Chat Application
        </Typography>
        <Typography color="gray" className="mb-8 font-normal">
          A Real Time Chat Application: React, JSX, JavaScript
        </Typography>
        <Typography color="gray" className="mb-8 font-normal">
          Login Details: Username:bazon, Password:123456, Username:bazinga,
          Password:123
        </Typography>
        <a
          href="https://66d7c3b798399010d7470b9a--coruscating-cheesecake-cc0f9c.netlify.app"
          className="inline-block"
          target="_blank"
        >
          <Button variant="text" className="flex items-center gap-2">
            Visit Site
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </a>
      </CardBody>
    </Card>
  );
}

export function HorizontalCard2() {
  return (
    <Card className="w-full m-2  flex-row">
      <CardHeader
        shadow={false}
        floated={false}
        className="m-0 w-2/5 shrink-0 rounded-r-none"
      >
        <img
          src="https://images.unsplash.com/photo-1636227008751-1b2708e36e7b?q=80&w=2837&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="card-image"
          className="h-full w-full object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          Electrify
        </Typography>
        <Typography color="gray" className="mb-8 font-normal">
          EV awareness website
        </Typography>
        <a
          href="https://66d10855ad8823c84bde78d0--shiny-zuccutto-09fcf1.netlify.app"
          target="_blank"
          className="inline-block"
        >
          <Button variant="text" className="flex items-center gap-2">
            Visit Site
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </a>
      </CardBody>
    </Card>
  );
}

export function HorizontalCard3() {
  return (
    <Card className="w-full m-2  flex-row">
      <CardHeader
        shadow={false}
        floated={false}
        className="m-0 w-2/5 shrink-0 rounded-r-none"
      >
        <img
          src="https://images.unsplash.com/photo-1593941707882-a5bba14938c7?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="card-image"
          className="h-full w-full object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          EV Store
        </Typography>
        <Typography color="gray" className="mb-8 font-normal">
          Simple EV store: React, Javascript, JSX
        </Typography>
        <a
          href="https://electrifys.shop"
          target="_blank"
          className="mb-inline-block"
        >
          <Button variant="text" className="flex items-center gap-2">
            Visit Site
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </a>
      </CardBody>
    </Card>
  );
}

function Work() {
  return (
    <div>
      <div className="block lg:hidden">
        <CardDefault />
        <CardDefault1 />
        <CardDefault2 />
        <CardDefault3 />
      </div>

      <div className="hidden lg:block">
        <HorizontalCard />
        <HorizontalCard1 />
        <HorizontalCard2 />
        <HorizontalCard3 />
      </div>
    </div>
  );
}

export default Work;
