import React from "react";
import "./App.css";

import { Avatar } from "flowbite-react";
import { BrowserRouter, Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Contact from "./contact";
import About from "./about";
import Navbr from "./navbar";

const Home = () => {
  return (
    <div className="justify-center bg-gradient-to-tr from-white to-slate h-auto w-auto items-center ">
      <div className="justify-center bg-gradient-to-tr from-white to-slate h-full w-screen flex items-center ">
        <div className="w-3/4 h-screen items-center hidden lg:block">
          <img
            className="h-screen w-screen rounded-lg object-cover object-center shadow-xl shadow-blue-gray-900/50"
            src="https://images.unsplash.com/photo-1562075950-23ba332df71c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="nature image"
          />
        </div>

        <div className="w-screen h-screen items-center block lg:hidden">
          <img
            className="h-screen w-screen rounded-lg object-cover object-center shadow-xl shadow-blue-gray-900/50"
            src="https://images.unsplash.com/photo-1579882392185-581038fbc8c5?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="nature image"
          />
        </div>

        <div className="w-1/4 items-center hidden lg:block ">
          <div className="space-y-1 font-medium dark:text-white">
            <div className="text-2xl text-center">Stephen</div>
            <div className="text-xl text-black dark:text-gray-400 text-center">
              <code className="text-black"> Software Engineer</code>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
